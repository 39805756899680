import React from 'react';

import { BLOCK_TYPE } from '@components/blocks';

import { Container } from './embedBlock.styles';

export interface EmbedBlockProps {
  content: { content?: string } | string;
}

export const EmbedBlock = ({ content }: EmbedBlockProps) => {
  const htmlContent = typeof content === 'string' ? content : content?.content || '';
  return (
    <Container dangerouslySetInnerHTML={{ __html: htmlContent }} data-testid={BLOCK_TYPE.EMBED_HTML} />
  );
};

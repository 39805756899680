import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GoogleMap, Marker, InfoWindow, LoadScript } from '@react-google-maps/api';
import { isNil } from 'ramda';

import { useBreakpoint } from '@hooks/useBreakpoint';

import { MAP_STYLES, GOOGLE_API_KEY } from './maps.constants';
import { Container, Icon, InnerInfoWindow, MarkerArrowRightCustom, MarkerDescription, MarkerTitle } from './map.styles';

export interface MapProps {
  centerLatitude?: number | null;
  centerLongitude?: number | null;
  csvFile?: any;
  zoom?: number;
  fullWidth: boolean;
  markers: MarkerType[];
}

export type MarkerType = {
  name: string;
  latitude: number;
  longitude: number;
  link: string;
  description: string;
};

export type BoundsType = {
  south: number;
  north: number;
  east: number;
  west: number;
};

export interface MarkerProps {
  name: string;
  description: string;
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  link: string;
}

function getBounds(markers: MarkerType[]) {
  const bounds = {
    north: -180,
    south: 180,
    east: -180,
    west: 180,
  };

  markers.forEach(({ latitude, longitude }) => {
    if (bounds.north < latitude) bounds.north = latitude;
    if (bounds.south > latitude) bounds.south = latitude;
    if (bounds.east < longitude) bounds.east = longitude;
    if (bounds.west > longitude) bounds.west = longitude;
  });

  return bounds;
}

function calculateCenterPosition(
  markers: MarkerType[],
  bounds: BoundsType,
  centerLatitude?: number | null,
  centerLongitude?: number | null
) {
  if (markers.length === 0) return null;

  // Calculate center points based on CMS data
  if (!isNil(centerLatitude) && !isNil(centerLongitude)) {
    return {
      lat: centerLatitude,
      lng: centerLongitude,
    };
  }

  const center = {
    lat: bounds.south + (bounds.north - bounds.south) / 2,
    lng: bounds.east + (bounds.west - bounds.east) / 2,
  };

  return center;
}

function parseDescription(description: string): string {
  return description.split('\n').join('<br />');
}

function calculateZoom(bounds: BoundsType, mapDim: { width: number; height: number }, zoom: number | undefined) {
  if (!isNil(zoom)) return zoom;
  if (mapDim.width === 0) return 2;

  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoomLevel(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const latFraction = (latRad(bounds.north) - latRad(bounds.south)) / Math.PI;
  const lngDiff = bounds.east - bounds.west;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoomLevel(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoomLevel(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

const getMapWidth = ({ ref }: { ref: React.RefObject<HTMLDivElement> }) => {
  const clientWidth = ref.current?.children[0].clientWidth;

  if (clientWidth) return clientWidth - 35;
  return 0;
};

export const MapComponent = ({ markers, centerLatitude, centerLongitude, zoom, fullWidth }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useBreakpoint();
  const bounds = useMemo(() => getBounds(markers), [markers]);
  const calcCenter = useMemo(() => calculateCenterPosition(markers, bounds, centerLatitude, centerLongitude), [
    bounds,
    markers,
    centerLatitude,
    centerLongitude,
  ]);
  const [realMapSize, setMapSize] = useState({ width: 0, height: 600 });
  const calcZoom = calculateZoom(bounds, realMapSize, zoom);

  useEffect(() => {
    const clientWidth = getMapWidth({ ref });
    if (clientWidth) setMapSize((prev) => ({ ...prev, width: clientWidth }));
  }, [isMobile]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY as string}>
      <Container ref={ref}>
        <GoogleMap
          data-test-id="map"
          options={{
            styles: MAP_STYLES,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            zoom: calcZoom,
            center: calcCenter,
          }}
          onLoad={() => setMapSize((prev) => ({ ...prev, width: getMapWidth({ ref }) }))}
          mapContainerStyle={{
            maxWidth: fullWidth ? '100%' : '760px',
            height: '600px',
            margin: '0 auto',
            marginBottom: '2rem',
          }}
        >
          {markers?.map((marker) => (
            <MarkerComponent
              key={marker.name}
              name={marker.name}
              description={parseDescription(marker.description)}
              position={{
                lat: marker.latitude,
                lng: marker.longitude,
              }}
              link={marker.link}
            />
          ))}
        </GoogleMap>
      </Container>
    </LoadScript>
  );
};

const MarkerComponent = ({ position, name, description, link }: MarkerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Marker
      position={position}
      icon={{
        path: 'M0 25a25 25 0 1 0 50 0a25 25 0 1 0 -50 0',
        anchor: new google.maps.Point(25, 25),
        fillColor: 'black',
        fillOpacity: 1,
        scale: 0.7,
        strokeColor: 'white',
        strokeWeight: 2,
      }}
      onClick={() => setIsOpen((currVal) => !currVal)}
    >
      {isOpen && (
        <InfoWindow>
          <InnerInfoWindow href={link}>
            <MarkerDescription>
              <MarkerTitle>{name}:</MarkerTitle>
              <br />
              <span dangerouslySetInnerHTML={{ __html: description }} /> <br />
            </MarkerDescription>
            <Icon>
              <MarkerArrowRightCustom />
            </Icon>
          </InnerInfoWindow>
        </InfoWindow>
      )}
    </Marker>
  );
};

import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { FONT_SIZE, FONT_WEIGHT, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

const textStyles = css`
  ${typography({ size: FONT_SIZE.M, weight: FONT_WEIGHT.REGULAR })};
  margin: 0;
`;

export const Container = styled.div`
  margin: ${OFFSET.M} auto;
  display: flex;
  flex-direction: column;
  max-width: 100vw;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const ReadMore = styled.span`
  ${textStyles};
`;

export const Article = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${OFFSET.M};
  position: relative;

  &:not(:last-child)::after {
    content: '';
    width: calc(100vw - ${OFFSET.M} - ${OFFSET.M});
    height: 1px;
    display: block;
    background: ${COLORS.SILVER};
    position: absolute;
    bottom: 0;
  }

  ${media.tablet} {
    width: 50%;
    padding: 0 ${OFFSET.M};
    margin: ${OFFSET.M} 0;

    &:not(:last-child)::after {
      content: none;
    }

    &:hover {
      cursor: pointer;

      ${ReadMore} {
        border-bottom: 2px solid ${COLORS.BLACK};
      }
    }

    &:not(:last-child) {
      border-right: 1px solid ${COLORS.SILVER};
    }
  }
`;

export const Figure = styled.figure`
  margin: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const Header = styled.header`
  margin-bottom: ${OFFSET.M};

  ${media.tablet} {
    height: 154px;
  }
`;

export const Title = styled.h3`
  ${textStyles};
  text-transform: uppercase;
`;

export const Description = styled.section`
  p {
    ${textStyles};
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: pre-wrap;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';
import { CONTAINER_PADDING, CONTAINER_WIDTH, ASSET_PLACEMENT } from '@components/blocks/blocks.constants';
import { FONT_SIZE, typography } from '@theme/font';

export const Caption = styled.figcaption`
  ${typography({ size: FONT_SIZE.XS })};
`;

export const Container = styled.div<{ placement: ASSET_PLACEMENT }>`
  position: relative;
  overflow: visible;
  ${media.tablet} {
    margin: ${OFFSET.L} auto ${OFFSET.M};
    ${Caption} {
      margin: ${OFFSET.XS} 0 0;
    }
  }

  ${({ placement }) =>
    placement === ASSET_PLACEMENT.WIDE
      ? css`
          margin: ${OFFSET.L} 0 ${OFFSET.M};

          ${Caption} {
            margin: ${OFFSET.XS} 40px 0;
          }

          ${media.tablet} {
            margin: ${OFFSET.L} 20px ${OFFSET.M};
          }

          @media (max-width: 768px) {
            margin: ${OFFSET.L} -${CONTAINER_PADDING}px ${OFFSET.M};
            ${Caption} {
              margin: ${OFFSET.XS} ${OFFSET.L} 0;
            }
          }
        `
      : css`
          max-width: 100%;
          @media (max-width: 768px) {
            margin: ${OFFSET.L} auto ${OFFSET.M};
          }

          ${Caption} {
            margin: 0 40px 0;
          }

          ${media.tablet} {
            max-width: 715px;
          }
        `}
`;

export const ImageContainer = styled.figure`
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

import React from 'react';
import NextLink from 'next/link';
import { always } from 'ramda';

import { ImageSet, LinkSet } from '@definitions/common.types';
import { OptimisedImageWrapper } from '@components/optimisedImageWrapper';
import { renderWhenTrue } from '@utils/rendering';
import { useImage } from '@hooks/useImage';
import { useLink } from '@hooks/useLink';

import { ASSET_PLACEMENT } from '../blocks.constants';

import { Container, Heading, Link, Image, Caption } from './advertisementBlock.styles';

export interface AdvertisementBlockProps {
  value: {
    image: ImageSet;
    link: LinkSet;
    placement: ASSET_PLACEMENT;
    mobileImage?: ImageSet;
    caption?: string;
    headerText?: string;
  };
}

export const AdvertisementBlock = ({ value }: AdvertisementBlockProps) => {
  const { image: desktopImage, mobileImage, caption, link, headerText, placement } = value;
  const { href, target } = useLink({ link });
  const image = useImage({ desktopImage, mobileImage });

  const renderCaption = renderWhenTrue(always(<Caption dangerouslySetInnerHTML={{ __html: caption || '' }} />));

  const renderHeader = renderWhenTrue(always(<Heading>{headerText}</Heading>));

  const renderImage = () => (
    <OptimisedImageWrapper>
      <Image src={image.src} title={image.title} alt={image.alt} />
    </OptimisedImageWrapper>
  );

  const renderLink = () => (
    <a
      onClick={() => {
        window.history.pushState({}, '', href);
      }}
      href={href}
    >
      <Link target={target}>{renderImage()}</Link>
    </a>
  );

  return (
    <Container placement={placement}>
      {renderHeader(!!headerText)}
      {renderLink()}
      {renderCaption(!!caption)}
    </Container>
  );
};

import React, { useState } from 'react';
import { always } from 'ramda';

import { renderWhenTrue } from '@utils/rendering';

import { ASSET_PLACEMENT } from '../blocks.constants';

import { CONTAINER_ID, NONE_EXPANDED, OVERLAY_ID } from './shoppableImage.constants';
import { Point } from './point.component';
import { Container, Image, Overlay, ImageContainer, Caption } from './shoppableImage.styles';

type TPoint = {
  coordinates: {
    left: number;
    top: number;
  };
  bubble: {
    artistName: string;
    title: string;
    medium: string;
    year: string;
    price: number;
    link: {
      type: string;
      slug: string;
      displayText: string | null;
    };
  };
  defaultOpen: boolean;
};

interface IShoppableImage {
  content: {
    image: {
      title: string;
      alt: string;
      urls: {
        url: string;
        size: string;
      }[];
    };
    points: TPoint[];
    caption: string;
    placement: ASSET_PLACEMENT;
  };
}

export function ShoppableImage({ content }: IShoppableImage) {
  const {
    placement,
    caption,
    image: { urls, alt, title },
    points,
  } = content;
  const findExpandedIndex = points.findIndex((point) => point.defaultOpen);
  const [expandedIndex, setExpandedIndex] = useState(findExpandedIndex);
  const [isTouched, setIsTouched] = useState(false);

  const handleOnExpanded = (index: number, defaultOpen: boolean) => () => {
    setExpandedIndex(index);
    if (!defaultOpen) setIsTouched(true);
  };

  const handleOnCollapsed = (defaultOpen: boolean) => () =>
    (!defaultOpen || isTouched) && setExpandedIndex(NONE_EXPANDED);

  const handleOnOverlayClick = (event: React.MouseEvent) => {
    const targetId = (event.target as HTMLDivElement).id;
    if (targetId === OVERLAY_ID) setExpandedIndex(NONE_EXPANDED);
  };

  const renderCaption = renderWhenTrue(always(<Caption>{caption}</Caption>));

  return (
    <Container id={CONTAINER_ID} placement={placement}>
      <ImageContainer>
        <Image src={urls[0].url} alt={alt} title={title} />
        <Overlay id={OVERLAY_ID} onClick={handleOnOverlayClick}>
          {points.map(({ coordinates: { left, top }, bubble, defaultOpen }, index) => (
            <Point
              onExpanded={handleOnExpanded(index, defaultOpen)}
              onCollapsed={handleOnCollapsed(defaultOpen)}
              key={index}
              x={left}
              y={top}
              bubble={bubble}
              isExpanded={expandedIndex === index}
            />
          ))}
        </Overlay>
      </ImageContainer>
      {renderCaption(!!caption)}
    </Container>
  );
}

import React, { Fragment } from 'react';
import NextLink from 'next/link';
import { always } from 'ramda';

import { LinkSet, LinkSetType } from '@definitions/common.types';
import { OptimisedImageWrapper } from '@components/optimisedImageWrapper';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { useLink } from '@hooks/useLink';

import { ASSET_PLACEMENT } from '../blocks.constants';
import { VideoBlock } from '../videoBlock';

import { CONTAINER_WIDTH } from './columns.constants';
import {
  ColumnLeft,
  ColumnRight,
  Container,
  ImageItem,
  Row,
  TextItem,
  imageWrapperStyles,
  VideoItemWrapper,
  Caption,
} from './columns.styles';

export enum COLUMN_VARIANT {
  IMAGE = 'image',
  TEXT = 'text',
  VIDEO = 'video',
}

enum CONTENT_WIDTH {
  FULL = 'full_width',
  MEDIUM = 'medium_width',
}

type ColumntImageType = {
  caption: string;
  image: {
    alt: string;
    title: string;
    urls: {
      size: string;
      url: string;
    }[];
  };
  link?: LinkSet;
};

type ColumnType =
  | {
      id: string;
      type: COLUMN_VARIANT.TEXT;
      value: string;
    }
  | {
      id: string;
      type: COLUMN_VARIANT.IMAGE;
      value: ColumntImageType;
    }
  | {
      id: string;
      type: COLUMN_VARIANT.VIDEO;
      value: {
        autoplay: boolean;
        caption: string;
        videoUrl: string;
      };
    };

type ColumnsValueType = {
  column_0: ColumnType[];
  column_1: ColumnType[];
};

export interface ColumnsProps {
  content: {
    columns: {
      id: string;
      type: string;
      value: ColumnsValueType;
    }[];
    proportions: number;
    verticalAlignment: 'top' | 'center' | 'bottom';
    contentWidth: 'full_width' | 'medium_width';
  };
}

export const Columns = ({ content }: ColumnsProps) => {
  const { columns, proportions, verticalAlignment, contentWidth } = content;
  const leftColumnWidth = proportions;
  const rightColumnWidth = CONTAINER_WIDTH - proportions;
  const isContainerFullWidth = contentWidth === CONTENT_WIDTH.FULL;

  const renderImageWithLink = (value: ColumntImageType) => {
    const { link, caption, image } = value || {};
    const { target, href } = useLink({ link });

    const renderCaption = renderWhenTrue(always(<Caption dangerouslySetInnerHTML={{ __html: caption || '' }} />));
    const renderLink = renderWhenTrueOtherwise(
      always(
        <a
          href={`${href || ''}`}
          onClick={() => {
            window.history.pushState({}, '', href);
          }}
        >
          <a target={target}>
            <OptimisedImageWrapper>
              <ImageItem src={image.urls[0].url} isCaption={!!caption} />
            </OptimisedImageWrapper>
          </a>
        </a>
      ),
      always(
        <OptimisedImageWrapper>
          <ImageItem src={image.urls[0].url} isCaption={!!caption} />
        </OptimisedImageWrapper>
      )
    );

    return (
      <>
        {renderLink(!!href)}
        {renderCaption(!!caption)}
      </>
    );
  };

  const renderColumnContent = (column: ColumnType[]) =>
    column.map(
      ({ type, value, id }: ColumnType): JSX.Element => (
        <Fragment key={id}>
          {typeof value !== 'string' && 'image' in value && renderImageWithLink(value)}
          {typeof value === 'string' && <TextItem dangerouslySetInnerHTML={{ __html: value }} />}
          {typeof value !== 'string' && 'videoUrl' in value && (
            <VideoItemWrapper>
              <VideoBlock
                videoUrl={value.videoUrl}
                autoplay={value.autoplay}
                caption={value.caption}
                placement={ASSET_PLACEMENT.WIDE}
              />
            </VideoItemWrapper>
          )}
        </Fragment>
      )
    );

  return (
    <Container isFullWidth={isContainerFullWidth}>
      {columns.map(
        ({ value, id }: { value: ColumnsValueType; id: string }): JSX.Element => (
          <Row verticalAlign={verticalAlignment} key={id}>
            <ColumnLeft width={leftColumnWidth}>{renderColumnContent(value.column_0)}</ColumnLeft>

            <ColumnRight width={rightColumnWidth}>{renderColumnContent(value.column_1)}</ColumnRight>
          </Row>
        )
      )}
    </Container>
  );
};

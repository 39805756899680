import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { COLORS } from '@theme/colors';
import { OFFSET } from '@theme/helpers';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';

import { ASSET_PLACEMENT, CONTAINER_PADDING, CONTAINER_WIDTH } from '../blocks.constants';

export const Container = styled.div<{ placement: ASSET_PLACEMENT }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto ${OFFSET.M};

  ${({ placement }) =>
    placement === ASSET_PLACEMENT.WIDE
      ? css`
          margin: ${OFFSET.S} 0;

          figcaption {
            margin: ${OFFSET.XS} ${OFFSET.S} 0;
          }

          ${media.tablet} {
            margin: ${OFFSET.L} ${CONTAINER_PADDING * 1.5}px ${OFFSET.M};

            figcaption {
              margin: ${OFFSET.XS} 0 0;
            }
          }
        `
      : css`
          max-width: calc(100% - ${OFFSET.M});
          margin: 0 ${OFFSET.S} ${OFFSET.M};

          figcaption {
            margin: ${OFFSET.XS} 0 0;
          }

          ${media.mobile} {
            max-width: 100%;
            margin: 0 auto ${OFFSET.M};
          }

          ${media.tablet} {
            max-width: ${CONTAINER_WIDTH - 2 * CONTAINER_PADDING}px;
          }
        `}
`;

export const Heading = styled.h4`
  ${typography({})};
  margin: ${OFFSET.S} 0;
  text-transform: uppercase;
  text-align: center;
  color: ${COLORS.DARK_GREY};
`;

export const Link = styled.a`
  position: relative;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const Caption = styled.figcaption`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};

  a {
    border-bottom: 1px solid currentColor;
  }

  p {
    margin: 0;
  }
`;

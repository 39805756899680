import styled from 'styled-components';

import { OFFSET } from '@theme/helpers';
import { LETTER_SPACING, LINE_HEIGHT } from '@theme/font';

export const Container = styled.div<{
  hasSpacingTop: boolean;
  hasSpacingBottom: boolean;
  backgroundColor: string;
  textColor: string;
}>`
  letter-spacing: ${LETTER_SPACING.NORMAL};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  padding-top: ${({ hasSpacingTop }) => (hasSpacingTop ? OFFSET.M : 0)};
  padding-bottom: ${({ hasSpacingBottom }) => (hasSpacingBottom ? OFFSET.M : 0)};
  overflow: auto;

  ul {
    line-height: ${LINE_HEIGHT.L};
  }
`;

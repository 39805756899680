import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { FONT_SIZE, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

import { containerStyles, paragraphStyles, linkStyles } from '../blocks.styles';

type ContainerProps = {
  isFullWidth: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${containerStyles};
  max-width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '1188px')};

  a {
    ${linkStyles};
  }
`;

type RowProps = {
  verticalAlign: string;
};

export const Row = styled.div<RowProps>`
  flex-direction: column;
  align-items: ${({ verticalAlign }) => translateVerticalAlignment(verticalAlign)};

  ${media.tablet} {
    flex-direction: row;
    display: flex;
    gap: ${OFFSET.M};
    margin-bottom: ${OFFSET.M};
  }
`;

type ColumnProps = {
  width: number;
};

const translateVerticalAlignment = (value: string) =>
  ({
    top: 'flex-start',
    middle: 'center',
    bottom: 'baseline',
  }[value]);

const generateColumnStyles = (width: number) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: ${OFFSET.M};

  > a {
    border: none;
  }

  ${media.tablet} {
    justify-content: unset;
    margin-bottom: unset;
    width: ${width}%;
  }
`;

export const imageWrapperStyles = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

export const ImageItem = styled.img<{ isCaption: boolean }>`
  max-width: 100%;
  height: auto;

  ${media.tablet} {
    margin: 54px auto;
    margin-bottom: ${({ isCaption }) => (isCaption ? '0px' : '54px')};
  }
`;

export const TextItem = styled.span`
  ul,
  ol {
    margin: 0;
  }

  p,
  li {
    ${paragraphStyles};
    min-height: 1px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${media.tablet} {
    margin: 54px auto;
  }
`;

export const VideoItemWrapper = styled.div`
  width: 100%;
  height: 100%;

  > div {
    margin: 0;
  }
`;

export const ColumnLeft = styled.div<ColumnProps>`
  ${({ width }) => generateColumnStyles(width)};
`;

export const ColumnRight = styled.div<ColumnProps>`
  ${({ width }) => generateColumnStyles(width)};
`;

export const Caption = styled.figcaption`
  ${typography({ size: FONT_SIZE.XS, spacing: LETTER_SPACING.M })};
  margin: ${OFFSET.XS} 0 0;

  p {
    margin: 0;
  }

  a {
    border-bottom: 1px solid currentColor;
  }
`;

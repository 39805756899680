import React from 'react';
import NextLink from 'next/link';

import { OptimisedImageWrapper } from '@components/optimisedImageWrapper';

import { Container, Article, Title, Description, ReadMore, Image, Figure, Header } from './twoPieceEditorial.styles';

type Article = {
  description: string;
  image: Image;
  subtitle: string;
  title: string;
  url: string;
};

type Image = {
  alt: string;
  title: string;
  urls: {
    size: string;
    url: string;
  }[];
};

type Articles = {
  type: string;
  value: {
    linkText: string;
    article: Article;
    image: {
      image: Image;
      mobileImage: Image | null;
    };
  };
  id: string;
}[];

export interface TwoPieceEditorialProps {
  content: {
    liveStartAt: string;
    liveEndAt: string;
    articles: Articles;
  };
}

export const TwoPieceEditorial = ({ content: { articles } }: TwoPieceEditorialProps) => (
  <Container>
    {articles.map(({ value: { linkText, article, image: { image } } }, idx) => (
      <a
        key={idx}
        onClick={() => {
          window.history.pushState({}, '', `/${article.url}`);
        }}
        href={`/${article.url}`}
      >
        <Article>
          <Header>
            <Title>
              {article.title} {article.subtitle}
            </Title>
            <Description dangerouslySetInnerHTML={{ __html: article.description }} />
            <ReadMore>→ {linkText || 'Read more'}</ReadMore>
          </Header>

          <Figure>
            <OptimisedImageWrapper>
              <Image src={image.urls[0].url} alt={image.alt} title={image.title} />
            </OptimisedImageWrapper>
          </Figure>
        </Article>
      </a>
    ))}
  </Container>
);

import styled, { css } from 'styled-components';

import { Z_INDEX } from '@utils/constants';
import { media } from '@theme/media';
import { FONT_WEIGHT, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

import Arrow from '../../../assets/images/arrow-right.svg';

const INITIAL_SIZE = '30px';
const EXPANDED_WIDTH = '246px';
const MOBILE_EXPANDED_WIDTH = '186px';
const INITIAL_BORDER_RADIUS = '16px';
const EXPANDED_BORDER_RADIUS = '0px';
const EXPANDED_PADDING = '12px';
const REVERT_TRANSITION =
  'border-radius 0.15s ease-in-out, width 0.15s ease-in-out 0.1s, height 0.15s ease-in-out 0.1s, transform 0s ease-in-out 0.5s';

export const Circle = styled.div<{
  x: number;
  y: number;
  isExpanded: boolean;
  revertX: boolean;
  revertY: boolean;
  contentHeight: string;
}>`
  will-change: transform, width, height, border-radius, top, left;
  width: ${({ isExpanded }) => (isExpanded ? MOBILE_EXPANDED_WIDTH : INITIAL_SIZE)};
  height: ${({ isExpanded, contentHeight }) => (isExpanded ? contentHeight : INITIAL_SIZE)};
  border-radius: ${({ isExpanded }) => (isExpanded ? EXPANDED_BORDER_RADIUS : INITIAL_BORDER_RADIUS)};
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.25);
  padding: 0;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(${({ y }) => y}% - (${INITIAL_SIZE} / 2));
  left: calc(${({ x }) => x}% - (${INITIAL_SIZE} / 2));
  cursor: pointer;
  color: black;
  z-index: ${({ isExpanded }) => (isExpanded ? Z_INDEX.SHOPPABLE_IMAGE_BUBBLE : '0')};
  transition: border-radius 0.15s ease-in-out, width 0.15s ease-in-out 0.1s, height 0.15s ease-in-out 0.1s,
    transform 0.15s ease-in-out;
  ${({ isExpanded, revertX, revertY }) => {
    if (revertX && !revertY) {
      return isExpanded
        ? css`
            transform: translateX(calc(-100% + ${INITIAL_SIZE}));
          `
        : css`
            transform: translateX(0);
            transition: ${REVERT_TRANSITION};
          `;
    }

    if (revertY && !revertX) {
      return isExpanded
        ? css`
            transform: translateY(calc(-100% + ${INITIAL_SIZE}));
          `
        : css`
            transform: translateY(0);
            transition: ${REVERT_TRANSITION};
          `;
    }

    if (revertX && revertY) {
      return isExpanded
        ? css`
            transform: translate(calc(-100% + ${INITIAL_SIZE}), calc(-100% + ${INITIAL_SIZE}));
          `
        : css`
            transform: translate(0, 0);
            transition: ${REVERT_TRANSITION};
          `;
    }
  }};

  ${media.mobile} {
    width: ${({ isExpanded }) => (isExpanded ? EXPANDED_WIDTH : INITIAL_SIZE)};
    height: ${({ isExpanded, contentHeight }) => (isExpanded ? contentHeight : INITIAL_SIZE)};
  }
`;

export const Content = styled.div<{ isExpanded: boolean }>`
  transition: opacity 0.2s ease-in-out 0.15s, visibility 0.2s ease-in-out 0.15s, transform 0s ease-in-out 0.25s;
  ${({ isExpanded }) =>
    isExpanded
      ? css`
          opacity: 1;
          visibility: visible;
          transform: scale(1);
          width: 100%;
        `
      : css`
          width: 0;
          opacity: 0;
          transform: scale(0);
          visibility: hidden;
          transition: none;
        `}
`;

export const Container = styled.div`
  display: flex;
  min-width: ${MOBILE_EXPANDED_WIDTH};
  padding: ${EXPANDED_PADDING};

  ${media.mobile} {
    min-width: ${EXPANDED_WIDTH};
  }
`;

export const PlusIconContainer = styled.span<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isExpanded }) =>
    !isExpanded
      ? css`
          transition: all 0.4s ease-in-out;
          opacity: 1;
          visibility: visible;
          transform: scale(1);
        `
      : css`
          width: 0;
          opacity: 0;
          transform: scale(0);
          visibility: hidden;
        `}
`;

const ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 140px;
`;

const Text = styled.div`
  ${typography({ weight: FONT_WEIGHT.REGULAR })};
  margin: 0;
`;

export const ArtistName = styled(Text)`
  text-transform: uppercase;
`;

export const Title = styled(Text)`
  ${ellipsis};
`;

export const Year = styled(Text)``;

export const Price = styled(Text)``;

export const Medium = styled(Text)``;

export const TitleRow = styled.div`
  display: flex;
  column-gap: 4px;
`;

export const ArtworkInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ArrowIcon = styled(Arrow)`
  width: 42px;
  height: 42px;
  margin-top: -10px;
  margin-left: -10px;
  flex-shrink: 0;
  display: none;

  ${media.mobile} {
    display: block;
  }
`;

export const MobileCta = styled(Text)`
  text-decoration: underline;
  margin-top: ${OFFSET.S};

  ${media.mobile} {
    display: none;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';

import PlusIcon from '../../../assets/images/plus.svg';

import {
  ArtistName,
  Circle,
  Content,
  Container,
  PlusIconContainer,
  Price,
  Medium,
  Year,
  Title,
  TitleRow,
  ArtworkInfo,
  ArrowIcon,
  MobileCta,
} from './point.styles';
import { MIN_HEIGHT } from './point.constants';

export interface IPoint {
  x: number;
  y: number;
  bubble: {
    artistName: string;
    title: string;
    medium: string;
    year: string;
    price: number;
    link: {
      type: string;
      slug: string;
      displayText: string | null;
    };
  };
  onExpanded: () => void;
  onCollapsed: () => void;
  isExpanded: boolean;
}

export function Point({ x, y, bubble, onExpanded, isExpanded, onCollapsed }: IPoint) {
  const [contentHeight, setContentHeight] = useState<string>(`${MIN_HEIGHT}px`);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    artistName,
    title,
    medium,
    price,
    year,
    link: { slug },
  } = bubble;

  useEffect(() => {
    if (containerRef.current) {
      const height = `${containerRef.current.offsetHeight}px`;
      setContentHeight(height);
    }
  }, [containerRef.current]);

  return (
    <Circle
      x={x}
      y={y}
      isExpanded={isExpanded}
      onMouseEnter={onExpanded}
      onMouseLeave={onCollapsed}
      onTouchStart={onExpanded}
      revertX={x > 50}
      revertY={y > 50}
      contentHeight={contentHeight}
    >
      <Content isExpanded={isExpanded}>
        <Link href={`/${slug}`}>
          <Container ref={containerRef}>
            <ArrowIcon />
            <ArtworkInfo>
              <ArtistName>{artistName}:</ArtistName>
              <TitleRow>
                <Title>{title},</Title>
                <Year>{year}</Year>
              </TitleRow>
              <Medium>{medium}</Medium>
              <Price>${price}</Price>
              <MobileCta>See more</MobileCta>
            </ArtworkInfo>
          </Container>
        </Link>
      </Content>
      <PlusIconContainer isExpanded={isExpanded}>
        <PlusIcon />
      </PlusIconContainer>
    </Circle>
  );
}

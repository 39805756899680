import styled from 'styled-components';

import { CONTAINER_PADDING } from '@components/blocks';
import { media } from '@theme/media';

import MarkerArrowRight from '../../assets/images/marker-arrow-right.svg';

export const InnerInfoWindow = styled.a`
  display: flex;
`;

export const MarkerTitle = styled.span`
  text-transform: uppercase;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  top: calc(50% - 20px);
`;

export const MarkerArrowRightCustom = styled(MarkerArrowRight)`
  transform: scale(1.5);
`;

export const MarkerDescription = styled.div`
  margin-right: 20px;
`;

export const Container = styled.div`
  margin: 0 ${CONTAINER_PADDING}px;

  ${media.tablet} {
    margin: 0 ${CONTAINER_PADDING * 1.5}px;
  }
`;

import styled from 'styled-components';

import { containerStyles } from '@components/blocks/blocks.styles';
import { media } from '@theme/media';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  ${containerStyles};
  margin: ${OFFSET.M} auto;
  display: flex;
  justify-content: center;

  ${media.tablet} {
    margin: ${OFFSET.L} auto;
  }
`;
